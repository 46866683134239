import React, {useEffect} from "react"
import {navigate} from "gatsby"
import PropTypes from "prop-types"
import {useSpring, animated} from 'react-spring'

import {FirebaseContext, useAuth} from "../Firebase"
import { Provider } from 'react-redux'
import store from '../store/configureStore'

import Loading from "./Loading"

const Layout = (props) => {

	const {user, userDetails, userCourses, managedUsers, firebase, loading} = useAuth()

	const fadeIn = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
		delay: 500
	})

	useEffect(() => {
		!loading && !user &&
			navigate("/")

	})


	return (
		<FirebaseContext.Provider value={{user, userDetails, userCourses, managedUsers, firebase, loading}}>
			<Provider store={store}>
				{!loading ?
					<animated.div style={fadeIn}>
						{ props.children }
					</animated.div>
				: <Loading/>}
			</Provider>
		</FirebaseContext.Provider>
	)

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
