import {
  useEffect,
  useState
} from "react"
import {
  navigate
} from "gatsby"
import getFirebaseInstance from "./firebase"
import loadFirebaseDependencies from "./loadFirebaseDependencies"

function useAuth() {
  const [user, setUser] = useState(null)
  const [firebase, setFirebase] = useState(null)
  const [loading, setLoading] = useState(true)

  const [userDetails, setUserDetails] = useState()
  const [userCourses, setUserCourses] = useState([])
  const [userAllCourses, setUserAllCourses] = useState([])
  const [managedUsers, setManagedUsers] = useState([])

  useEffect(() => {

    let unsubscribe
    let publicProfileUnsubscribe

    loadFirebaseDependencies.then(app => {
      const firebaseInstance = getFirebaseInstance(app)
      setFirebase(firebaseInstance)
      
      unsubscribe = firebaseInstance.auth.onAuthStateChanged(userResult => {
        if (userResult) {
          const userData = userResult._delegate
          firebaseInstance.getUserData({
            userId: userData.uid
          }).then(r => {
            setUser({
              ...userData,
              username: r.empty ? null : r.docs[0].id
            })
          }).catch(error => {
            console.log(error.message)
          })

          const email = userData.email
          const query = `
        						query ($email: [String]) {
        							user(email: $email) {
                        uid
        								id
        								fullName
        								username
        								email
        								... on User {
        									prefixTitle
        									postNominalLetters
        									company
        									appAdmin
        									userCourses {
        										... on userCourses_BlockType {
        											course {
        												id
        												slug
        												title
        												... on courses_courseOverview_Entry {
        													courseDescription
        													courseFeatureImage {
        														... on courseImages_Asset {
        											              transformCourseImages {
        											                srcset,
        											                srcsetWebp,
        											                maxSrcsetWidth,
        											                placeholderBox,
        											              }
        											            }
        													}
        													children {
        														__typename
        														id
        														slug
        														children {
        															__typename
        															id
        															slug
        														}
        													}
        													resourceArticle {
        														__typename
        														title
        														url
        														... on legislation_list_Entry {
        															articleExcerpt
        														}
        														... on technical_list_Entry {
        															articleExcerpt
        														}
        														... on blog_articles_article_Entry {
        															articleExcerpt
        														}
        														... on news_articles_article_Entry {
        															articleExcerpt
        														}
        													}
        													resourceExternalLinks {
        														__typename
        														title
        														... on external_links_external_links_Entry {
        															articleSource
        															articleExcerpt
        															externalLink
        														}
        													}
                                  courseDownloads {
                        						title
                                    url
                        						... on courseDownloads_Asset {
                                      articleSource
                                      articleExcerpt
                                    }
                                  }
        												}
        											}
        											expiryDate
                              unlock
        										}
        									}
        									managedUsers {
        										name
        									}
        								}
        							}
        						}`
          fetch(process.env.GATSBY_CRAFTQL_URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.GATSBY_CRAFTQL_AUTH}`
              },
              body: JSON.stringify({
                variables: {
                  email
                },
                query: query,
              }),
            })
            .then(res => res.json())
            .then(res => {
              const thisUser = res.data ? res.data.user : null
              if (thisUser == null) {
                console.log('user doesnt exist in craft')
                firebaseInstance.logout().then(() => navigate("/"))
                return null
              } else {
                setUserDetails({
                  uid: thisUser.uid,
                  id: thisUser.id,
                  fullName: thisUser.fullName,
                  username: thisUser.username,
                  email: thisUser.email.toLowerCase(),
                  prefixTitle: thisUser.prefixTitle,
                  postNominalLetters: thisUser.postNominalLetters,
                  company: thisUser.company,
                  admin: thisUser.appAdmin
                })
                thisUser.userCourses.map(course => setUserCourses(userCourses => [...userCourses,
                  {
                    content: course.course[0],
                    expiryDate: course.expiryDate,
                    unlock: course.unlock
                  }
                ]))
                setUserAllCourses(thisUser.userCourses)
                setManagedUsers(thisUser.managedUsers)
              }
            })

        } else {
          setUser(null);
          setUserDetails()
          setUserCourses([])
          setUserAllCourses([])
          setManagedUsers([])
        }
        setLoading(false);
      })
    })

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }

      if (publicProfileUnsubscribe) {
        publicProfileUnsubscribe()
      }
    }
  }, [])

  return {
    user,
    userDetails,
    userCourses,
    userAllCourses,
    managedUsers,
    firebase,
    loading
  }
}

export default useAuth
