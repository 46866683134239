import firebaseConfig from "./config";
import { navigate } from "gatsby"

import store from '../store/configureStore';
import { hasLoaded } from '../store/reducers/loadedSlice';
import { updateFontSize } from '../store/reducers/fontSizeSlice';
import { updateTheme } from '../store/reducers/themeSlice';
import { updatePitch, updateRate, updateVoice } from '../store/reducers/speechSlice';

class Firebase {
	
	constructor(app) {
		if (!firebaseInstance) {
			app.initializeApp(firebaseConfig);

			this.auth = app.auth();
			this.db = app.firestore();
			this.functions = app.functions();
			this.storage = app.storage();
		}
	}

	async getUserData({ userId }) {
		return this.db.collection('users').where('userId', '==', userId).get()
	}

	async updateUserProgress({ course, page, nextPage }) {
		const updateUserProgressCallable = this.functions.httpsCallable('updateUserProgress')
		return updateUserProgressCallable({
			course,
			page,
			nextPage,
		})
	}

	async updateCourseActivity({ delegate, course, status }) {
		const updateCourseActivityCallable = this.functions.httpsCallable('updateCourseActivity')
		return updateCourseActivityCallable({
			delegate,
			course,
			status
		})
	}

	async submitQuiz({ course, page, attempts, complete }) {
		const submitQuizCallable = this.functions.httpsCallable('submitQuiz')
		return submitQuizCallable({
			course,
			page,
			attempts,
			complete
		})
	}

	async saveExam({ course, answers }) {
		const saveExamCallable = this.functions.httpsCallable('saveExam')
		return saveExamCallable({
			course,
			answers
		}).then(() => {
			console.log('Exam has been saved');
		})
	}

	async deleteSavedExam({ course }) {
		const deleteSavedExamCallable = this.functions.httpsCallable('deleteSavedExam')
		return deleteSavedExamCallable({
			course
		}).then(() => {
			console.log('Saved exam has been deleted');
		})
	}

	async submitExam({ automated, course, attempts, maxAttempts, highScore, score, answers, complete, result }) {
		const submitExamCallable = this.functions.httpsCallable('submitExam')
		return submitExamCallable({
			automated,
			course,
			attempts,
			maxAttempts,
			highScore,
			score,
			answers,
			complete,
			result
		}).catch(error => {
			throw new this.functions.https.HttpsError('unknown', 'ERROR1', { message: error.message })
		});
	}

	async markExam({ delegate, course, highScore, complete, result }) {
		const markExamCallable = this.functions.httpsCallable('markExam')
		return markExamCallable({
			delegate,
			course,
			highScore,
			complete,
			result
		})
	}

	async createCert({ certId, courseId, courseName, delegateId, delegateName, delegateEmail, delegatePostNominal, delegateCompany, index }) {
		const createCertCallable = this.functions.httpsCallable('createCert')
		return createCertCallable({
			index,
			certId,
			courseId,
			courseName,
			delegateId,
			delegateName,
			delegateEmail,
			delegatePostNominal,
			delegateCompany,
		})
	}

	async markAttempt({ delegate, course, attempt, score }) {
		const markAttemptCallable = this.functions.httpsCallable('markAttempt')
		return markAttemptCallable({
			delegate,
			course,
			attempt,
			score,
		})
	}

	async addAttempt({ delegate, course, maxAttempts }) {
		const addAttemptCallable = this.functions.httpsCallable('addAttempt')
		return addAttemptCallable({
			delegate,
			course,
			maxAttempts
		})
	}

	async sendMessage({ delegate, author, message }) {
		const sendMessageCallable = this.functions.httpsCallable('sendMessage')
		return sendMessageCallable({
			delegate,
			author,
			message,
		})
	}

	async sendGlobalMessage({ subject, message }) {
		const sendGlobalMessageCallable = this.functions.httpsCallable('sendGlobalMessage')
		return sendGlobalMessageCallable({
			subject,
			message
		})
	}

	async setAlertMessage({ message }) {
		const setAlertMessageCallable = this.functions.httpsCallable('setAlertMessage')
		return setAlertMessageCallable({
			message
		})
	}

	subscribeToGlobalMessages({ onSnapshot }) {
		return this.db.collection('globalMessages')
			.orderBy('dateCreated', 'desc')
			.onSnapshot((onSnapshot))
	}

	subscribeToSettings({ onSnapshot }) {
		return this.db.collection('settings').doc('global')
			.onSnapshot((onSnapshot))
	}

	subscribeToSettingsCerts({ onSnapshot }) {
		return this.db.collection('settings').doc('certs')
			.onSnapshot((onSnapshot))
	}

	subscribeToAllUsers({ onSnapshot }) {
		return this.db.collection('users').onSnapshot((onSnapshot))
	}

	subscribeToUser({ userEmail }) {
		return this.db.collection('users').doc(userEmail)
			.onSnapshot((onSnapshot) => {
				if(onSnapshot && onSnapshot.data()) {
					if(onSnapshot.data().fontSize) {
						store.dispatch(updateFontSize(onSnapshot.data().fontSize))
					}
					if(onSnapshot.data().theme) {
						store.dispatch(updateTheme(onSnapshot.data().theme))
					}
					if(onSnapshot.data().speechPitch) {
						store.dispatch(updatePitch(onSnapshot.data().speechPitch))
					}
					if(onSnapshot.data().speechRate) {
						store.dispatch(updateRate(onSnapshot.data().speechRate))
					}
					if(onSnapshot.data().speechVoice) {
						store.dispatch(updateVoice(onSnapshot.data().speechVoice))
					}
				}
				store.dispatch(hasLoaded(true))
			});
	}

	async updateUserSettings({ fontSize, theme, speechPitch, speechRate, speechVoice }) {
		const updateUserSettingsCallable = this.functions.httpsCallable('updateUserSettings')
		return updateUserSettingsCallable({
			fontSize, theme, speechPitch, speechRate, speechVoice
		})
	}

	subscribeToUserCerts({ userEmail, courseId, onSnapshot }) {
		this.db.collection('users').doc(userEmail).collection('courses').doc(courseId).collection('certs')
			.orderBy('dateCreated', 'asc')
			.onSnapshot((onSnapshot))
	}

	subscribeToUserMessages({ userEmail, onSnapshot }) {
		return this.db.collection('users').doc(userEmail).collection('messages')
			.orderBy('dateCreated', 'asc')
			.onSnapshot((onSnapshot))
	}

	subscribeToUserProgress({ userEmail, courseId, onSnapshot }) {
		return this.db.collection('users').doc(userEmail).collection('courses').doc(courseId).collection('progress')
			.onSnapshot((onSnapshot))
	}

	subscribeToUserSavedExam({ userEmail, courseId, onSnapshot }) {
		return this.db.collection('users').doc(userEmail).collection('courses').doc(courseId).collection('progress').doc('exam').collection('saved')
			.onSnapshot((onSnapshot))
	}

	subscribeToUserExamAttempts({ userEmail, courseId, onSnapshot }) {
		return this.db.collection('users').doc(userEmail).collection('courses').doc(courseId).collection('progress').doc('exam').collection('attempts')
			.onSnapshot((onSnapshot))
	}

	async login({ email, password }) {
		return this.auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
			var user = userCredential.user;
			console.log(user)
		});
	}

	async logout() {
		await this.auth.signOut().then(() => navigate("/"))
	}
}

let firebaseInstance;

function getFirebaseInstance(app) {
	if (!firebaseInstance && app) {
		firebaseInstance = new Firebase(app);
		return firebaseInstance;
	} else if (firebaseInstance) {
		return firebaseInstance
	} else {
		return null;
	}
}

export default getFirebaseInstance;
