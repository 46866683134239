import { createSlice } from '@reduxjs/toolkit'

export const speechSlice = createSlice({
  name: 'speech',
  initialState: {
    pitch: 1,
    rate: 1,
    voice: 0
  },
  reducers: {
    updatePitch: (state, action) => {
      state.pitch = action.payload
    },
    updateRate: (state, action) => {
      state.rate = action.payload
    },
    updateVoice: (state, action) => {
      state.voice = action.payload
    }
  }
})

export const { updatePitch, updateRate, updateVoice } = speechSlice.actions

export const selectSpeech = state => state.speech

export default speechSlice.reducer