import { createSlice } from '@reduxjs/toolkit'

export const fontSizeSlice = createSlice({
  name: 'fontSize',
  initialState: {
    value: 1
  },
  reducers: {
    updateFontSize: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateFontSize } = fontSizeSlice.actions

export const selectFontSize = state => state.fontSize.value

export default fontSizeSlice.reducer