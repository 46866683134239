import { configureStore } from '@reduxjs/toolkit'
import loadedReducer from './reducers/loadedSlice'
import fontSizeReducer from './reducers/fontSizeSlice'
import speechReducer from './reducers/speechSlice'
import themeReducer from './reducers/themeSlice'

export default configureStore({
  reducer: {
    loaded: loadedReducer,
    fontSize: fontSizeReducer,
    speech: speechReducer,
    theme: themeReducer
  }
})