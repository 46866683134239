import React from "react"

import "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"

import Layout from "./src/components/structures/Layout"

import "./src/styles/index.scss"
import 'lazysizes'

export const wrapRootElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>
}
