import React, { useState } from "react"
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import LogoIconSVG from "../../images/logos/ionactive-logo-icon.svg"

const Wrapper = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`
const Loader = styled(LogoIconSVG)`
	opacity: 1;
`

const Loading = () => {
	
	const [resetTurn, setResetTurn] = useState(false)
	
	const turn = useSpring({
		from: { transform: `rotate(0deg)` }, 
		to: { transform: `rotate(360deg)` },
		onRest: () => setResetTurn(state => !state),
		reset: resetTurn,
		config: {
			duration: 3000
		}
	})

	return (
		<Wrapper>
			<animated.div style={turn}>
				<Loader opacity="0" width="150px" height="150px"/>
			</animated.div>
		</Wrapper>
	)

}

export default Loading