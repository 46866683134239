import { createSlice } from '@reduxjs/toolkit'

export const loadedSlice = createSlice({
  name: 'loaded',
  initialState: {
    value: false
  },
  reducers: {
    hasLoaded: state => {
      if(state.value === false) {
        state.value = true
      }
    }
  }
})

export const { hasLoaded } = loadedSlice.actions

export const selectLoaded = state => state.loaded.value

export default loadedSlice.reducer