exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-certificate-js": () => import("./../../../src/templates/certificate.js" /* webpackChunkName: "component---src-templates-certificate-js" */),
  "component---src-templates-exam-js": () => import("./../../../src/templates/exam.js" /* webpackChunkName: "component---src-templates-exam-js" */),
  "component---src-templates-module-js": () => import("./../../../src/templates/module.js" /* webpackChunkName: "component---src-templates-module-js" */),
  "component---src-templates-overview-js": () => import("./../../../src/templates/overview.js" /* webpackChunkName: "component---src-templates-overview-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */)
}

